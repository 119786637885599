import { graphql, Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import log from "loglevel"
import React from "react"
import ButtonList from "../components/button-list"
import FAQs from "../components/faqs"
import ImageGridWithZoom from "../components/image-grid-zoom"
import Layout from "../components/layout"
import Notes from "../components/notes"
import PhaseOutWarning from "../components/phase-out-warning"
import RefrigerantSeries from "../components/refrigerant-series"
import TitleWithCopyLink from "../components/title-copy-link"
import { matchDocumentsToFiles } from "../utils/documents"

const Thermostats = ({ data, pageContext }) => {
  const product = data?.product?.nodes?.[0] || {}
  const category = pageContext.category
  const subcategories = pageContext.category.subcategories
  const selectedSubcategoryId = pageContext.subcategoryId
  const faqs = data.faqs.nodes

  product.images = (
    data.images.edges?.map(edge => edge.node?.parent) || []
  ).filter(img => !img.name.toLowerCase().includes("landing"))

  const oneImage =
    data.extraImages.edges.length && data.extraImages.edges?.[0]?.node?.parent

  product.wiringImages = data.wiringImages.edges.map(edge => edge.node?.parent)

  product.accessories?.forEach(accessory => {
    if (accessory.file) {
      accessory.path = (
        (
          data.documents.edges.find(edge =>
            edge.node.name.includes(accessory.file)
          ) || {}
        ).node || {}
      ).publicURL
    }
  })

  product.documents = matchDocumentsToFiles(
    product,
    data.documents?.edges,
    data.submittalSheet?.edges
  )

  log.info(product, data.documents, pageContext)

  return (
    <Layout title={product.name} navItems={pageContext.categories || []}>
      <div className="uk-section-xsmall uk-container uk-container-large">
        <ul className="uk-breadcrumb">
          <li>
            <span>{category.name}</span>
          </li>
          <li>
            <Link to={`/category/${category._id}/sub/${selectedSubcategoryId}`}>
              {product.category}
            </Link>
          </li>
          <li>
            <span>{product.productSeries}</span>
          </li>
        </ul>
        <div className="uk-section-xsmall">
          <div className="uk-container uk-container-large uk-text-center">
            {/* <h2>{category.name}</h2> */}
            <ButtonList
              items={subcategories}
              parentId={category._id}
              selectedItemId={selectedSubcategoryId}
            ></ButtonList>
          </div>
        </div>

        <div className="product uk-section-xsmall">
          <h1 className="uk-text-center white-space-pre-line">
            {product.name}
          </h1>
          <p className="uk-text-center">
            <span className="uk-text-bold">Model: </span>
            {product.modelNumber || product.productSeries}
          </p>
          <RefrigerantSeries value={product.refrigerantSeries} />
          <PhaseOutWarning
            categoryId={category._id}
            warning={product.phaseOutWarning}
            replacement={product.replacementProductSeries}
          />
          <ImageGridWithZoom
            images={product.images}
            title={""}
            maxWidth={"400px"}
            section={false}
          ></ImageGridWithZoom>
          <Notes title={"Compatibility"} items={product.compatibility} />
        </div>

        <div className="uk-section-xsmall">
          <div
            className="uk-child-width-expand@s uk-grid-small uk-grid-match uk-flex uk-flex-wrap uk-flex-center"
            data-uk-grid
          >
            <div className="">
              <Notes title={"Features"} items={product.features} />
            </div>
            <div className="">
              <Notes
                title={"Additional Features"}
                items={product.additionalFeatures}
              />
            </div>
          </div>
        </div>

        {!!product.video ? (
          <div className="uk-section-xsmall uk-text-center">
            <iframe
              id="ytplayer"
              type="text/html"
              width="720"
              height="405"
              src={product.video}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          <></>
        )}

        <div className="uk-section-xsmall">
          <div
            className="uk-child-width-expand@s uk-grid-small uk-grid-match uk-flex uk-flex-wrap uk-flex-center"
            data-uk-grid
          >
            <div className="">
              <Notes
                title={"Features unique to specific indoor units"}
                items={product.additionalAccessories}
              />
            </div>
          </div>
        </div>

        <ImageGridWithZoom
          images={product.wiringImages}
          title={"Wiring Diagram"}
        ></ImageGridWithZoom>

        {product.includeDaikinOne ? (
          <div className="uk-section-xsmall uk-text-center">
            <div className="uk-padding-small">
              <OutboundLink
                href="https://daikinone.com/"
                target="_blank"
                className="uk-button round uk-button-primary"
              >
                Learn more on Daikin's One+ page
              </OutboundLink>
            </div>
            {!!oneImage ? (
              <ImageGridWithZoom
                images={[oneImage]}
                title={""}
                section={true}
              ></ImageGridWithZoom>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}

        <div className="uk-section-xsmall">
          <div
            className="uk-child-width-expand@s uk-grid-small uk-grid-match uk-flex uk-flex-wrap"
            data-uk-grid
          >
            {(product.accessories || []).length ? (
              <div className="uk-padding-small uk-flex uk-flex-column">
                <TitleWithCopyLink
                  title={"Related Accessories"}
                  type="h3"
                  style={{ flex: "none" }}
                />

                <div className="uk-overflow-auto">
                  <table
                    className="uk-table uk-table-striped  uk-table-small same-width-td uk-margin-auto"
                    style={{ maxWidth: "400px" }}
                  >
                    <thead className="thead-primary">
                      <tr>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {(product.accessories || []).map((accessory, i) => {
                        return (
                          <tr key={`accessories_row_${i}`}>
                            <td className="uk-text-bold text-dark">
                              {!!accessory.path ? (
                                <OutboundLink
                                  href={accessory.path}
                                  target="_blank"
                                >
                                  {accessory.name}
                                </OutboundLink>
                              ) : (
                                <>{accessory.name}</>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <></>
            )}
            {(product.documents || []).length ? (
              <div className="uk-padding-small uk-flex uk-flex-column">
                <TitleWithCopyLink
                  title={"Documents"}
                  type="h3"
                  style={{ flex: "none" }}
                />
                <div className="uk-overflow-auto">
                  <table
                    className="uk-table uk-table-striped  uk-table-small same-width-td uk-margin-auto"
                    style={{ maxWidth: "400px" }}
                  >
                    <thead className="thead-primary">
                      <tr>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {(product.documents || []).map((document, i) => {
                        return (
                          <tr key={`docs_row_${i}`}>
                            <td className="uk-text-bold text-dark">
                              {!!document.path ? (
                                <OutboundLink
                                  href={document.path}
                                  target="_blank"
                                >
                                  {document.name}
                                </OutboundLink>
                              ) : (
                                <>{document.name}</>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <FAQs items={faqs} productSeries={product.productSeries}></FAQs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ThermostatProductAndImage(
    $productId: String
    $productIdRegex: String
    $extraImageRegex: String
    $imageId: String
    $categoryRegex: String
    $subcategoryRegex: String
    $documentIds: [String]
    $wiringRegex: String
  ) {
    images: allImageSharp(
      filter: { original: { src: { regex: $imageId } } }
      sort: { fields: [original___src], order: ASC }
    ) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...fluidImage
          }
        }
      }
    }
    extraImages: allImageSharp(
      filter: { original: { src: { regex: $extraImageRegex } } }
    ) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...fluidImage
          }
        }
      }
    }
    wiringImages: allImageSharp(
      filter: { original: { src: { regex: $wiringRegex } } }
    ) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...fluidImage
          }
        }
      }
    }
    submittalSheet: allFile(
      filter: { extension: { eq: "pdf" }, name: { regex: $productIdRegex } }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    documents: allFile(
      filter: { extension: { eq: "pdf" }, name: { in: $documentIds } }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    faqs: allFaqsJson(
      filter: {
        categoryId: { regex: $categoryRegex }
        subcategoryId: { regex: $subcategoryRegex }
      }
    ) {
      ...faqsForCategoryAndSubcategory
    }
    product: allProductsJson(filter: { _id: { eq: $productId } }) {
      nodes {
        _id
        category
        capacity
        capacityText
        depth
        height
        name
        productId
        productSeries
        modelNumber
        features
        notes
        subcategory
        width
        additionalFeatures
        compatibility
        accessories {
          name
          file
        }
        additionalAccessories
        documents {
          name
          file
        }
        includeDaikinOne
        video
        refrigerantSeries
        replacementProductSeries
        phaseOutWarning
        models {
          capacity
          CFM
          MCA
          MOP
          dimensions
          cooling
          heating
          modelNumber
          name
          powerSupply
          productSeries
          submittalSheet {
            url
            fileName
            name
            type
          }
        }
      }
    }
  }
`

export default Thermostats
